@import '../../../styles/variables.scss';

.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: $fd-space-space-4 0;

    @include for-tablet-up {
      padding: $fd-space-space-4;
      padding-bottom: 0;
  }
}

.heading {
    display: flex;
    align-items: center;
    gap: $fd-space-space-2;
    @include heading-xx-large;
    color: $fd-colors-content-default;

    @include for-mobile-down {
        padding-left: $fd-space-space-4;
        @include heading-x-large
    }
}

.link {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $fd-colors-content-link;
}

.button {
    display: flex;
    flex-wrap: nowrap;
    color: inherit;
}
