@import '../../../styles/variables.scss';

.heroContainer {
    background-color: $fd-colors-background-surface;
    font-weight: normal;
    box-shadow: $fd-shadows-elevations-bottom-low;
    display: flex;
    justify-content: center;
}

.cardContent {
    padding: $fd-space-space-4 0;
    @include page-container;
}


.numberFire {
    display: none;

    @include for-tablet-up {
      display: block;
      padding-bottom: $fd-space-space-2;
    }
}
