@import '../../../styles/variables.scss';

.heading {
    @include heading-xx-large;
    color: $fd-colors-content-default;
    padding: $fd-space-space-4;

    @include for-mobile-down {
        @include heading-x-large
    }
}

.container {
    font-weight: 400; 
    background-color: $fd-colors-background-surface;
    box-shadow: $fd-shadows-elevations-bottom-low;
    
    @include for-tablet-up {
      border-radius: $fd-radii-border-radius-default;
    }
}

.tabsContainer {
    display: flex; 
    justify-content: flex-start; 
    gap: $fd-space-space-4;
    overflow-x: auto;
    padding-left: $fd-space-space-4;
    padding-right: $fd-space-space-4;
    border-bottom: 1px solid $fd-colors-border-default;
    @include scrollbar-hide;
}

.cardContent {
    padding: 0;
    min-height: 416px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include for-tablet-up {
        padding: $fd-space-space-4;
        flex-direction: row;
        gap: $fd-space-space-4;
        min-height: 31.0625rem;
    }
}
