@import '../../styles/variables.scss';

.icon {
  height: 133px;
  width: 149px;
  margin-top: 5px; // no design token available for 5px
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: none;
  padding-top: $fd-space-space-1;
}