@import "styles/variables.scss";

.container {
  display: flex;
  flex-direction: column;

  height: 100%;
  padding: $fd-space-space-4;

  border: 1px solid $fd-colors-border-subtle;
  border-radius: $fd-radii-border-radius-default;
}

.title {
  @include heading-medium;
  
  @include for-small-tablet-up {
    @include heading-large;
  }

  color: $fd-colors-content-default;
  padding-bottom: $fd-space-space-4;
}

.imageArea {
  position: relative;
}

.authorBackground {
  background: linear-gradient(to bottom, #001B59, #174597);
  border-radius: $fd-radii-border-radius-default;

  height: 92px;
  overflow: hidden;

  img {
    width: 100%;
    opacity: 0.06;
  }
}

.authorProfileImg {
  display: flex;
  align-items: center;
  justify-content: center;

  background: white;
  border: 1px solid $fd-colors-border-subtle;
  border-radius: $fd-radii-border-radius-circle;
  
  position: absolute;
  bottom: -$fd-space-space-6;
  left: $fd-space-space-4;
  overflow: hidden;

  height: 90px;
  width: 90px;
}

.nameArea {
  padding-top: $fd-space-space-12;
  display: flex;
  align-items: center;

  .name {
    @include heading-medium;
  }

  .divider {
    margin: 0 $fd-space-space-2;
  }

  .sportsList {
    display: flex;
    gap: $fd-space-space-2;
  }
}

.bio {
  @include body-medium-regular;
  padding-top: $fd-space-space-1;
  padding-bottom: $fd-space-space-2;
}

.spacer {
  flex-grow: 1;
}

.prompt {
  @include heading-medium;
  color: $fd-colors-content-default;
  padding-top: $fd-space-space-2;
}