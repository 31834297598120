@import '../../styles/variables.scss';

.button {
  padding: $fd-space-space-3;
  border: 1px solid $fd-colors-core-blue;
  border-radius: $fd-radii-border-radius-default;
  width: 100%;
  text-align: center;
}

.buttonLabel {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  gap: $fd-space-space-2;
  color: $fd-colors-core-blue;
}
