@import '../../styles/variables.scss';

.container {
    display: flex;
    gap: $fd-space-space-2;

    @include for-mobile-down {
        display: none;
    }
}

.iconContainer {
    width: 17px;
    height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.iconButton {
    border: 1px solid $blue-d1;
    border-radius: 4px;
    padding: 8px;
    width: 31px;
    height: 31px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:disabled {
        color: $grey-l4;
        border-color: $grey-l4;
    }
}