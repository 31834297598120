@import 'styles/variables.scss';

.container {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));

  @include for-desktop-up {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 0;
  }

  @include for-xl-desktop-up {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: $fd-space-space-4;
  }
}

.col2Articles {
  display: grid;
  grid-column: span 1 / span 1;
}

.col3Articles {
  display: grid;
  grid-column: span 1 / span 1;

  @include for-desktop-up {
    grid-column: span 3 / span 3;
  }

  @include for-xl-desktop-up {
    grid-column: span 1 / span 1;
  }

  .divider {
    @include for-xl-desktop-up {
      display: none;
    }
  }
}