@import '../../styles/variables.scss';

.containerBoundary {
    @include page-container;
}

.legacy {
    @include for-mobile-down {
        padding: 0 16px;
    }
}