@import '../../styles/variables.scss';
@import '../../styles/breakpoints';

.button {
  border: 1px solid $blue;
  border-radius: 4px;
  font-size: 0.875rem;
  padding: 0.5rem 0.25rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
    background-color: $blue-l5;
  }

  &:disabled {
    cursor: default;
    border: 1px solid $grey-l3;
    background-color: $white;
  }
}

.handicap {
  font-weight: 600;
  color: $grey-d4;
  line-height: 15.4px;
}

.odds {
  font-weight: 700;
  color: $blue-d1;
  line-height: 17.5px;
}
