@import '../../styles/variables.scss';

.avatarContainer {
  position: relative;
}

.avatar {
  border-radius: 50%;
  border: 1px solid $grey-l3;
}

.teamImageContainer {
  border-radius: 50%;
  background-color: $grey-l5;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
