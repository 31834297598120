@import '../../styles/variables.scss';

h3.title {
    @include heading-xx-large;
    color: $fd-colors-content-default;
    display: flex;
    align-items: center;
    gap: 4px;

    span {
        margin-bottom: 5px;
    }

    @include for-mobile-down {
        @include heading-medium;
        text-align: left;
    }
}

.header {
    display: flex;
    justify-content: space-between;
    padding: 24px 16px;
}

.statusContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 444px;
}

.itemContainer {
    overflow: scroll;
    margin: 0 1rem;
    padding: 1rem 0;

    @include for-mobile-down {
        padding-right: 0;
        padding-bottom: 0;
    }
}

.scrollContainer {
    display: flex;
    gap: 16px;
    margin-right: 16px;

    .lastItem {
        padding-right: 5px;
    }

    @include for-mobile-down {
        flex-direction: column;
        margin-right: 0;
    }
}

.dropDownContainer {
    margin-left: 16px;
    width: 200px;

    @include for-mobile-down {
        margin-right: 16px;
        width: auto;
    }
}

.scrollControls {
    display: flex;
    gap: 8px;
}

.mobileToggle {
    padding: $fd-space-space-4;
    padding-top: 0;

    @include for-tablet-up {
        display: none;
    }
}

.liveContainer {
    display: flex;
    justify-content: flex-end;
    gap: 4px;

    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        padding: 2px;
        background-color: $red-d1;
        color: $white;
        font-size: 12px;

        span {
            text-transform: uppercase;
            line-height: 12px;
            letter-spacing: 1px;

            /** 
             * These properties address a spacing issue with the rendered font. It
             * ensures the "LIVE" word appears centered in its red box.
             */
            margin-top: 1px;
            margin-left: 1px;
        }
    }
}