@import '../../styles/variables.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.imageAndRecord {
    display: flex;
    align-items: center;
    width: 100%;

    .scoreOrRecord {
        font-family: 'Proxima Nova Condensed', sans-serif;
        font-size: 0.75rem;
        margin-left: 0.5rem;
        color: $grey-d1;
    }
}

.imageContainer {
    display: flex;
    color: $blue-d1;
    gap: 4px;
    align-items: center;
}