@import "../styles/variables.scss";

.mainContent {
    margin-bottom: 24px;
    @include for-mobile-down {
        margin-top: 0px;
    }
}

.heroContainer {
    margin: auto;
}

.boundaryContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.conditionalColsContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
}

.conditionalCols {
    grid-column: span 4;
}

.sectionContainer {
    margin-top: 24px;
}