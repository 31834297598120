@import '../../../styles/variables.scss';

.content {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.boxTemplate {
  @include for-tablet-up {
    padding: $fd-space-space-4;
  }
}
