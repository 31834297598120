// This file creates mixins that combine all the token variables for a given typography setting into one mixin.
// This is inline with the typescale found here https: //www.figma.com/design/524jb8ZmEXMGcb63bKQtTv/%E2%9A%A1%EF%B8%8F-TYPOGRAPHY-LIBRARY?node-id=24-11&node-type=CANVAS&t=eOb8XfwFgJ50mYAb-0

@import '@fanduel/formation-tokens/build/scss/fanduel.scss';

@mixin jumbo-xx-large {
    font-family: $fd-fonts-jumbo-xx-large;
    font-weight: $fd-font-weights-jumbo-xx-large;
    line-height: $fd-line-heights-jumbo-xx-large;
    font-size: $fd-font-sizes-jumbo-xx-large;
    letter-spacing: $fd-letter-spacings-jumbo-xx-large;
    text-transform: $fd-text-case-jumbo-xx-large;
    text-decoration: $fd-text-decoration-jumbo-xx-large;
}


@mixin jumbo-x-large {
    font-family: $fd-fonts-jumbo-x-large;
    font-weight: $fd-font-weights-jumbo-x-large;
    line-height: $fd-line-heights-jumbo-x-large;
    font-size: $fd-font-sizes-jumbo-x-large;
    letter-spacing: $fd-letter-spacings-jumbo-x-large;
    text-transform: $fd-text-case-jumbo-x-large;
    text-decoration: $fd-text-decoration-jumbo-x-large;
}

@mixin jumbo-large {
    font-family: $fd-fonts-jumbo-large;
    font-weight: $fd-font-weights-jumbo-large;
    line-height: $fd-line-heights-jumbo-large;
    font-size: $fd-font-sizes-jumbo-large;
    letter-spacing: $fd-letter-spacings-jumbo-large;
    text-transform: $fd-text-case-jumbo-large;
    text-decoration: $fd-text-decoration-jumbo-large;
}

@mixin jumbo-medium {
    font-family: $fd-fonts-jumbo-medium;
    font-weight: $fd-font-weights-jumbo-medium;
    line-height: $fd-line-heights-jumbo-medium;
    font-size: $fd-font-sizes-jumbo-medium;
    letter-spacing: $fd-letter-spacings-jumbo-medium;
    text-transform: $fd-text-case-jumbo-medium;
    text-decoration: $fd-text-decoration-jumbo-medium;
}

@mixin jumbo-small {
    font-family: $fd-fonts-jumbo-small;
    font-weight: $fd-font-weights-jumbo-small;
    line-height: $fd-line-heights-jumbo-small;
    font-size: $fd-font-sizes-jumbo-small;
    letter-spacing: $fd-letter-spacings-jumbo-small;
    text-transform: $fd-text-case-jumbo-small;
    text-decoration: $fd-text-decoration-jumbo-small;
}

@mixin heading-xx-large {
    font-family: $fd-fonts-heading-xx-large;
    font-weight: $fd-font-weights-heading-xx-large;
    line-height: $fd-line-heights-heading-xx-large;
    font-size: $fd-font-sizes-heading-xx-large;
    letter-spacing: $fd-letter-spacings-heading-xx-large;
    text-transform: $fd-text-case-heading-xx-large;
    text-decoration: $fd-text-decoration-heading-xx-large;
}

@mixin heading-x-large {
    font-family: $fd-fonts-heading-x-large;
    font-weight: $fd-font-weights-heading-x-large;
    line-height: $fd-line-heights-heading-x-large;
    font-size: $fd-font-sizes-heading-x-large;
    letter-spacing: $fd-letter-spacings-heading-x-large;
    text-transform: $fd-text-case-heading-x-large;
    text-decoration: $fd-text-decoration-heading-x-large;
}

@mixin heading-large {
    font-family: $fd-fonts-heading-large;
    font-weight: $fd-font-weights-heading-large;
    line-height: $fd-line-heights-heading-large;
    font-size: $fd-font-sizes-heading-large;
    letter-spacing: $fd-letter-spacings-heading-large;
    text-transform: $fd-text-case-heading-large;
    text-decoration: $fd-text-decoration-heading-large;
}

@mixin heading-medium {
    font-family: $fd-fonts-heading-medium;
    font-weight: $fd-font-weights-heading-medium;
    line-height: $fd-line-heights-heading-medium;
    font-size: $fd-font-sizes-heading-medium;
    letter-spacing: $fd-letter-spacings-heading-medium;
    text-transform: $fd-text-case-heading-medium;
    text-decoration: $fd-text-decoration-heading-medium;
}

@mixin heading-small {
    font-family: $fd-fonts-heading-small;
    font-weight: $fd-font-weights-heading-small;
    line-height: $fd-line-heights-heading-small;
    font-size: $fd-font-sizes-heading-small;
    letter-spacing: $fd-letter-spacings-heading-small;
    text-transform: $fd-text-case-heading-small;
    text-decoration: $fd-text-decoration-heading-small;
}

@mixin heading-x-small {
    font-family: $fd-fonts-heading-x-small;
    font-weight: $fd-font-weights-heading-x-small;
    line-height: $fd-line-heights-heading-x-small;
    font-size: $fd-font-sizes-heading-x-small;
    letter-spacing: $fd-letter-spacings-heading-x-small;
    text-transform: $fd-text-case-heading-x-small;
    text-decoration: $fd-text-decoration-heading-x-small;
}

@mixin body-x-large-regular {
    font-family: $fd-fonts-body-x-large-regular;
    font-weight: $fd-font-weights-body-x-large-regular;
    line-height: $fd-line-heights-body-x-large-regular;
    font-size: $fd-font-sizes-body-x-large-regular;
    letter-spacing: $fd-letter-spacings-body-x-large-regular;
    text-transform: $fd-text-case-body-x-large-regular;
    text-decoration: $fd-text-decoration-body-x-large-regular;
}

@mixin body-x-large-strong {
    font-family: $fd-fonts-body-x-large-strong;
    font-weight: $fd-font-weights-body-x-large-strong;
    line-height: $fd-line-heights-body-x-large-strong;
    font-size: $fd-font-sizes-body-x-large-strong;
    letter-spacing: $fd-letter-spacings-body-x-large-strong;
    text-transform: $fd-text-case-body-x-large-strong;
    text-decoration: $fd-text-decoration-body-x-large-strong;
}

@mixin body-x-large-strikethrough {
    font-family: $fd-fonts-body-x-large-regular-strikethrough;
    font-weight: $fd-font-weights-body-x-large-regular-strikethrough;
    line-height: $fd-line-heights-body-x-large-regular-strikethrough;
    font-size: $fd-font-sizes-body-x-large-regular-strikethrough;
    letter-spacing: $fd-letter-spacings-body-x-large-regular-strikethrough;
    text-transform: $fd-text-case-body-x-large-regular-strikethrough;
    text-decoration: $fd-text-decoration-body-x-large-regular-strikethrough;
}

@mixin body-x-large-strong-strikethrough {
    font-family: $fd-fonts-body-x-large-strong-strikethrough;
    font-weight: $fd-font-weights-body-x-large-strong-strikethrough;
    line-height: $fd-line-heights-body-x-large-strong-strikethrough;
    font-size: $fd-font-sizes-body-x-large-strong-strikethrough;
    letter-spacing: $fd-letter-spacings-body-x-large-strong-strikethrough;
    text-transform: $fd-text-case-body-x-large-strong-strikethrough;
    text-decoration: $fd-text-decoration-body-x-large-strong-strikethrough;
}

@mixin body-large-regular {
    font-family: $fd-fonts-body-large-regular;
    font-weight: $fd-font-weights-body-large-regular;
    line-height: $fd-line-heights-body-large-regular;
    font-size: $fd-font-sizes-body-large-regular;
    letter-spacing: $fd-letter-spacings-body-large-regular;
    text-transform: $fd-text-case-body-large-regular;
    text-decoration: $fd-text-decoration-body-large-regular;
}

@mixin body-large-strong {
    font-family: $fd-fonts-body-large-strong;
    font-weight: $fd-font-weights-body-large-strong;
    line-height: $fd-line-heights-body-large-strong;
    font-size: $fd-font-sizes-body-large-strong;
    letter-spacing: $fd-letter-spacings-body-large-strong;
    text-transform: $fd-text-case-body-large-strong;
    text-decoration: $fd-text-decoration-body-large-strong;
}

@mixin body-large-strikethrough {
    font-family: $fd-fonts-body-large-regular-strikethrough;
    font-weight: $fd-font-weights-body-large-regular-strikethrough;
    line-height: $fd-line-heights-body-large-regular-strikethrough;
    font-size: $fd-font-sizes-body-large-regular-strikethrough;
    letter-spacing: $fd-letter-spacings-body-large-regular-strikethrough;
    text-transform: $fd-text-case-body-large-regular-strikethrough;
    text-decoration: $fd-text-decoration-body-large-regular-strikethrough;
}

@mixin body-large-strong-strikethrough {
    font-family: $fd-fonts-body-large-strong-strikethrough;
    font-weight: $fd-font-weights-body-large-strong-strikethrough;
    line-height: $fd-line-heights-body-large-strong-strikethrough;
    font-size: $fd-font-sizes-body-large-strong-strikethrough;
    letter-spacing: $fd-letter-spacings-body-large-strong-strikethrough;
    text-transform: $fd-text-case-body-large-strong-strikethrough;
    text-decoration: $fd-text-decoration-body-large-strong-strikethrough;
}

@mixin body-medium-regular {
    font-family: $fd-fonts-body-medium-regular;
    font-weight: $fd-font-weights-body-medium-regular;
    line-height: $fd-line-heights-body-medium-regular;
    font-size: $fd-font-sizes-body-medium-regular;
    letter-spacing: $fd-letter-spacings-body-medium-regular;
    text-transform: $fd-text-case-body-medium-regular;
    text-decoration: $fd-text-decoration-body-medium-regular;
}

@mixin body-medium-strong {
    font-family: $fd-fonts-body-medium-strong;
    font-weight: $fd-font-weights-body-medium-strong;
    line-height: $fd-line-heights-body-medium-strong;
    font-size: $fd-font-sizes-body-medium-strong;
    letter-spacing: $fd-letter-spacings-body-medium-strong;
    text-transform: $fd-text-case-body-medium-strong;
    text-decoration: $fd-text-decoration-body-medium-strong;
}

@mixin body-medium-strikethrough {
    font-family: $fd-fonts-body-medium-regular-strikethrough;
    font-weight: $fd-font-weights-body-medium-regular-strikethrough;
    line-height: $fd-line-heights-body-medium-regular-strikethrough;
    font-size: $fd-font-sizes-body-medium-regular-strikethrough;
    letter-spacing: $fd-letter-spacings-body-medium-regular-strikethrough;
    text-transform: $fd-text-case-body-medium-regular-strikethrough;
    text-decoration: $fd-text-decoration-body-medium-regular-strikethrough;
}

@mixin body-medium-strong-strikethrough {
    font-family: $fd-fonts-body-medium-strong-strikethrough;
    font-weight: $fd-font-weights-body-medium-strong-strikethrough;
    line-height: $fd-line-heights-body-medium-strong-strikethrough;
    font-size: $fd-font-sizes-body-medium-strong-strikethrough;
    letter-spacing: $fd-letter-spacings-body-medium-strong-strikethrough;
    text-transform: $fd-text-case-body-medium-strong-strikethrough;
    text-decoration: $fd-text-decoration-body-medium-strong-strikethrough;
}

@mixin body-small-regular {
    font-family: $fd-fonts-body-small-regular;
    font-weight: $fd-font-weights-body-small-regular;
    line-height: $fd-line-heights-body-small-regular;
    font-size: $fd-font-sizes-body-small-regular;
    letter-spacing: $fd-letter-spacings-body-small-regular;
    text-transform: $fd-text-case-body-small-regular;
    text-decoration: $fd-text-decoration-body-small-regular;
}

@mixin body-small-strong {
    font-family: $fd-fonts-body-small-strong;
    font-weight: $fd-font-weights-body-small-strong;
    line-height: $fd-line-heights-body-small-strong;
    font-size: $fd-font-sizes-body-small-strong;
    letter-spacing: $fd-letter-spacings-body-small-strong;
    text-transform: $fd-text-case-body-small-strong;
    text-decoration: $fd-text-decoration-body-small-strong;
}

@mixin body-small-strikethrough {
    font-family: $fd-fonts-body-small-regular-strikethrough;
    font-weight: $fd-font-weights-body-small-regular-strikethrough;
    line-height: $fd-line-heights-body-small-regular-strikethrough;
    font-size: $fd-font-sizes-body-small-regular-strikethrough;
    letter-spacing: $fd-letter-spacings-body-small-regular-strikethrough;
    text-transform: $fd-text-case-body-small-regular-strikethrough;
    text-decoration: $fd-text-decoration-body-small-regular-strikethrough;
}

@mixin body-small-strong-strikethrough {
    font-family: $fd-fonts-body-small-strong-strikethrough;
    font-weight: $fd-font-weights-body-small-strong-strikethrough;
    line-height: $fd-line-heights-body-small-strong-strikethrough;
    font-size: $fd-font-sizes-body-small-strong-strikethrough;
    letter-spacing: $fd-letter-spacings-body-small-strong-strikethrough;
    text-transform: $fd-text-case-body-small-strong-strikethrough;
    text-decoration: $fd-text-decoration-body-small-strong-strikethrough;
}

@mixin body-x-small-regular {
    font-family: $fd-fonts-body-x-small-regular;
    font-weight: $fd-font-weights-body-x-small-regular;
    line-height: $fd-line-heights-body-x-small-regular;
    font-size: $fd-font-sizes-body-x-small-regular;
    letter-spacing: $fd-letter-spacings-body-x-small-regular;
    text-transform: $fd-text-case-body-x-small-regular;
    text-decoration: $fd-text-decoration-body-x-small-regular;
}

@mixin body-x-small-strong {
    font-family: $fd-fonts-body-x-small-strong;
    font-weight: $fd-font-weights-body-x-small-strong;
    line-height: $fd-line-heights-body-x-small-strong;
    font-size: $fd-font-sizes-body-x-small-strong;
    letter-spacing: $fd-letter-spacings-body-x-small-strong;
    text-transform: $fd-text-case-body-x-small-strong;
    text-decoration: $fd-text-decoration-body-x-small-strong;
}

@mixin body-x-small-strikethrough {
    font-family: $fd-fonts-body-x-small-regular-strikethrough;
    font-weight: $fd-font-weights-body-x-small-regular-strikethrough;
    line-height: $fd-line-heights-body-x-small-regular-strikethrough;
    font-size: $fd-font-sizes-body-x-small-regular-strikethrough;
    letter-spacing: $fd-letter-spacings-body-x-small-regular-strikethrough;
    text-transform: $fd-text-case-body-x-small-regular-strikethrough;
    text-decoration: $fd-text-decoration-body-x-small-regular-strikethrough;
}

@mixin body-x-small-strong-strikethrough {
    font-family: $fd-fonts-body-x-small-strong-strikethrough;
    font-weight: $fd-font-weights-body-x-small-strong-strikethrough;
    line-height: $fd-line-heights-body-x-small-strong-strikethrough;
    font-size: $fd-font-sizes-body-x-small-strong-strikethrough;
    letter-spacing: $fd-letter-spacings-body-x-small-strong-strikethrough;
    text-transform: $fd-text-case-body-x-small-strong-strikethrough;
    text-decoration: $fd-text-decoration-body-x-small-strong-strikethrough;
}

// ADD button and Label text styles here when needed.

@mixin label-medium {
    font-family: $fd-fonts-label-medium;
    font-weight: $fd-font-weights-label-medium;
    line-height: $fd-line-heights-label-medium;
    font-size: $fd-font-sizes-label-medium;
    letter-spacing: $fd-letter-spacings-label-medium;
    text-transform: $fd-text-case-label-medium;
    text-decoration: $fd-text-decoration-label-medium;
}

@mixin label-small {
    font-family: $fd-fonts-label-small;
    font-weight: $fd-font-weights-label-small;
    line-height: $fd-line-heights-label-small;
    font-size: $fd-font-sizes-label-small;
    letter-spacing: $fd-letter-spacings-label-small;
    text-transform: $fd-text-case-label-small;
    text-decoration: $fd-text-decoration-label-small;
}