@import '../../../styles/variables.scss';

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: $fd-colors-content-default;
    padding: $fd-space-space-4;
    background-color: $fd-colors-background-base;
    border-radius: $fd-radii-border-radius-default;
}

.notCondensed {
    @include for-lg-desktop-up {
        height: 130px;
    }
}


.titleAndArrow {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: $fd-space-space-2;
}

.title {
    font-weight: $fd-font-weights-font-weight-050;
    font-size: $fd-font-sizes-font-size-040;
    line-height: $fd-line-heights-line-height-030;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;  
    -webkit-line-clamp: 3;
    line-clamp: 3;
}

.boxArrow {
    min-width: $fd-space-space-5;
}

.description {
    padding-top: $fd-space-space-2;
}
