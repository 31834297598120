@import 'styles/variables.scss';

.container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: $fd-space-space-6;
  padding: $fd-space-space-6;
  padding-left: $fd-space-space-4;

  .text {
    flex-grow: 0;

    .title {
      @include heading-small;
    color: $fd-colors-content-default;

      flex-grow: 0;

      /* 2 lines maximum */
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      line-clamp: 2;
    }
  }

  .avatar {
    height: 60px;
    width: 60px;
    min-width: 60px;
  }
}
