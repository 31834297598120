@import 'styles/variables.scss';

.scrollItem {
  display: none;

  @include for-tablet-up {
    display: block;
  }
}

.hideAtMd {
  display: block;

  @include for-tablet-up {
    display: none;
  }
}