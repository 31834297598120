@import '../../../styles/variables.scss';

.container {
  background-color: $fd-colors-core-white;
  font-weight: $fd-font-weights-font-weight-020;
  box-shadow: $fd-shadows-elevations-bottom-low;

  @include for-tablet-up {
    border-radius: $fd-radii-border-radius-default;
  }
}

.boxDivider {
  margin: $fd-space-space-6 $fd-space-space-4;

  @include for-tablet-up {
    margin: $fd-space-space-6;
  }
}

.boxError {
  padding: $fd-space-space-4;
}
