@import '../../styles/variables.scss';

.container {
  display: flex;
  flex-direction: column;
}

.background {
  background-color: $fd-colors-background-surface;
  height: 100%;
  border-radius: $fd-radii-border-radius-default;
  box-shadow: $fd-shadows-elevations-bottom-low;
}

.padding {
  padding: $fd-space-space-4;
}

.paddingLg {
  padding: 0;

  @include for-tablet-up {
    padding: $fd-space-space-4;
  }
}

.spotlightImg {
  width: 100%;
}

.rounded {
  border-radius: $fd-radii-border-radius-default;
}

.roundedLg {
  border-radius: none;

  @include for-tablet-up {
    border-radius: $fd-radii-border-radius-default;
  }
}

.articleInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: $fd-space-space-2;
  padding: $fd-space-space-4;

  @include for-tablet-up {
    gap: $fd-space-space-1;
  }
}

.title {
  @include heading-medium;
  color: $fd-colors-content-default;

  @include for-tablet-up {
    @include heading-x-large;
  }
}

.snippet {
  font-size: $fd-font-sizes-body-medium-regular;
  color: $fd-colors-content-default;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;  
  -webkit-line-clamp: 3;
  line-clamp: 3;
}
