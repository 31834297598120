@import "styles/variables.scss";

.container {
  font-size: $fd-font-sizes-font-size-040;
  line-height: 16px;

  .bullet {
    padding-left: $fd-space-space-1;
  }

  time {
    padding-left: $fd-space-space-1;
  }
}

span.source {
  color: $fd-colors-content-default;
}
