@import '../../styles/variables.scss';

$odds-btn-gap: 4px;
$odds-btn-width: 60px;
$odds-btn-small-width: 52px;

@mixin consistent-padding {
    padding: 0.75rem 1rem;
}

.card {
    background-color: $white;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-color: $grey-l4 !important;

    @include for-desktop-up {
        width: 362px;
    }
}

.header {
    font-family: "Proxima Nova Condensed", sans-serif;
    font-size: 12px;
    color: $grey-d1;
    line-height: 15px;
    letter-spacing: 1px;
    height: 2.5rem;
    padding: 1rem;
    width: 100%;
    border-bottom: none;
    
    @include for-desktop-up {
        height: 3rem;
        padding-bottom: 0.5rem;
    }

    .labelContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;

        .tvContainer {
            display: flex;
            gap: 8px;
            align-items: center;
            text-transform: uppercase;
    
            span {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 80px;
            }
        }
    }
}

.matchupContainer {
    @include for-mobile-down {
        display: none;
    }
}

.gameContainer {
    grid-column: span 4 / span 4;
    flex-grow: 1;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;

    @include consistent-padding();

    hr {
        width: 100%;
    }
}

.marketHeadingsContainer {
    grid-column: span 4 / span 4;

    display: flex;
    justify-content: flex-end;

    @include consistent-padding();
    // Overrides consistent-padding
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    text-transform: uppercase;
    font-size: 0.625rem;
    font-family: 'Proxima Nova Condensed', sans-serif;
    color: $grey-d1;
    background-color: $grey-l5;
    border-top: 1px solid $grey-l4;
    border-bottom: 1px solid $grey-l4;

    .marketHeadings {
        width: (3 * $odds-btn-width) + (2 * $odds-btn-gap); 
        
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: $odds-btn-gap;

        text-align: center;

        @include for-extra-small-mobile-down {
            width: (3 * $odds-btn-small-width) + (2 * $odds-btn-gap);
        }

        span {
            // Lowers the headings' text slightly to account for a line positioning quirk that
            // appears to be endemic to the Proxima Nova Condensed font.
            transform: translateY(1px);
            letter-spacing: 1px;
        }
    }
}

.scoreContainer {
    display: flex;
    justify-content: space-between;
}

.marketsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: $odds-btn-gap;

    .oddsBtn {
        height: 56px;
        width: $odds-btn-width;

        @include for-extra-small-mobile-down {
            width: $odds-btn-small-width;
        }
    }
}

.separator {
    display: flex;
    align-items: center;
    gap: 2px;
    color: $grey-l3;
    margin: 6px 0;
    
    .symbol {
        font-size: 1rem;
        padding: 0 0.5rem;
    }
}
