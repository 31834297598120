@import "../../styles/variables.scss";

.divider {
  margin: 0;
  height: $fd-border-widths-border-width-010;
  border: none;
}

.light {
  background-color: $fd-colors-system-neutral-background-subtle;
}

.dark {
  background-color: $fd-colors-system-neutral-background-default;
}
