@import 'styles/variables.scss';

.loading {
  height: 644px;
}

.error {
  height: 407px;
}

.loading, .error {
  display: flex;
  align-items: center;
  justify-content: center;

  @include for-xl-desktop-up {
    height: 294px;
  }
}

.container {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));

  @include for-xl-desktop-up {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: $fd-space-space-6;
    padding-left: $fd-space-space-4;
    padding-right: $fd-space-space-4;
    padding-bottom: $fd-space-space-4;
  }

  .cards {
    grid-column: span 1 / span 1;
  }
}