@import '../../styles/variables.scss';

@mixin horizontal {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    .imageContainer {
        width: 133px;
    }

    .text {
        margin-top: 0;
        margin-right: $fd-space-space-2;
    }

    .title {
        @include body-medium-strong;
        -webkit-line-clamp: 2;
        line-clamp: 2;
    }

    .description {
        @include body-small-regular;
        -webkit-line-clamp: 1;
        line-clamp: 1;
    }

}

.container {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: flex-end;
    padding-top: $fd-space-space-4;
    padding-bottom: $fd-space-space-4;
    width: 100%;
    color: $fd-colors-content-default;

    @include for-mobile-down {
        @include horizontal;
    }

    &.background {
        background-color: $fd-colors-background-surface;
        border-radius: $fd-space-space-1;
        box-shadow: $fd-shadows-elevations-bottom-low;
    }

    &.horizontal {
        @include horizontal;
    }

    &.padding {
        padding: $fd-space-space-4;
    }
}

.text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: $fd-space-space-1;
    margin-top: $fd-space-space-4;
}

.text>a:first-child {
    line-height: 16px;
}

.title {
    @include heading-x-large;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;

    a {
        color: $fd-colors-content-default;
    }
}

.description {
    @include body-medium-regular;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    max-height: 54px;
}

.imageContainer {
    width: 100%;
    aspect-ratio: 16/9;
    position: relative;
    min-width: 133px;
    border-radius: $fd-space-space-1;
    overflow: hidden;

    img {
        object-fit: cover;
    }
}