@import 'styles/variables.scss';

.container {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: $fd-space-space-4;

  @include for-desktop-up {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.spotlightArticle {
  grid-column: span 1 / span 1;

  @include for-desktop-up {
    grid-column: span 2 / span 2;
  }
}

.articlesSection {
  display: grid;
  grid-column: span 1 / span 1;
}