@import '../../styles/variables.scss';

.container {
  display: flex;
  width: max-content;
  justify-content: center;
  height: 1.5rem;
  align-items: center;
  padding: 0.25rem;
  gap: 0.25rem;
  background-color: rgba($black, 0.05);
  border-radius: 50px;
  margin-bottom: 2px;

  .wpText {
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: $fd-colors-content-default;
    margin-top: 3px;
  }
}

.tooltip {
  width: 225px;
  left: 0;
  bottom: 24px;
  font-size: 14px;
}

.tooltip.rightSide{
  left: -150px;
}