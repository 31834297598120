@import '../../../styles/variables.scss';

.wrapper {
    position: relative;
    display: flex;
    align-items: center;
    min-width: 17px;
}

.tooltip {
    position: absolute;
    border: 1px solid $blue-d1;
    border-radius: 4px;
    background-color: $blue-l5;
    width: 496px;
    max-width: 75vw;
    display: flex;
    padding: 8px 12px 8px 16px;
    left: 25px;
    justify-content: space-between;

    p {
        font-size: 14px;
        font-weight: 400;
        line-height: 17.5px;
        text-align: left;
        color: $navy-d3;
        white-space: break-spaces;
    }
}

.hidden {
    display: none;
}