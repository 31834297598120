@import '../../styles/variables.scss';

.groupControl {
  display: flex;
  width: auto;
  color: $grey-d4;

  button {
    height: auto;
    cursor: pointer;
    padding: 2px 12px 14px 12px;
    background: none;
    border-bottom: 2px solid $white;
    color: $grey-d4;

    &.selected {
      font-weight: normal;
      border-bottom: 2px solid $blue;
    }
  }
}