@import 'styles/variables.scss';

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: $fd-space-space-4;
  padding: $fd-space-space-4;

  @include for-tablet-up {
    padding-bottom: 0;
  }
}

.header {
  display: flex;
  align-items: center;
  gap: $fd-space-space-2;

  @include heading-medium;
  color: $fd-colors-content-default;

  @include for-tablet-up {
    @include heading-large;
  }
}

.link {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  gap: $fd-space-space-2;
  color: $fd-colors-content-link;

  &:hover {
    cursor: pointer;
  }

  @include for-tablet-up {
    padding-left: $fd-space-space-4;
    padding-right: $fd-space-space-4;
    padding-top: 13.5px;
    padding-bottom: 13.5px;
  }
}