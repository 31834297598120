@import "styles/variables.scss";

.container {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: $fd-space-space-4;
  width: 100%;

  .articleCardsContainer {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 0;

    grid-column: span 1 / span 1;
  }

  .authorSpotlight {
    height: 100%;
    padding: $fd-space-space-4;
  }

  @include for-tablet-up {
    .articleCardsContainer {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .spotlightArticle,
    .divider {
      display: none;
    }
  }

  @include for-xl-desktop-up {
    grid-template-columns: repeat(3, minmax(0, 1fr));

    .articleCardsContainer {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 0;

      grid-column: span 2 / span 2;
    }

    .authorSpotlight {
      padding: 0;
    }
  }
}
