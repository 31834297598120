@import 'styles/variables.scss';

.container {
  display: grid;
  grid-column: span 3 / span 3;
  gap: $fd-space-space-4;

  @include for-desktop-up {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}

.spotlightArticle {
  grid-column: span 3 / span 3;
}

.articles {
  grid-column: span 3 / span 3;

  @include for-xl-desktop-up {
    grid-column: span 2 / span 2;
  }
}

.toolsContainer {
  grid-column: span 3 / span 3;

  @include for-desktop-up {
    grid-column: span 6 / span 6;
  }

  @include for-xl-desktop-up {
    grid-column: span 1 / span 1;
  }

  .toolsLayout {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: $fd-space-space-4;
    padding: $fd-space-space-4;
    padding-top: 0;

    @include for-desktop-up {
      flex-direction: row;
      padding: 0;
    }

    @include for-xl-desktop-up {
      flex-direction: column;
    }
  }
}
